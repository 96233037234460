import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Auth } from "aws-amplify";
import Header from "./components/Header/Header.jsx";
import HomePage from "./components/HomePage/HomePage.jsx";
import DownloadPage from "./components/Download/DownloadPage.jsx";
import Home from "./components/Home/Home.jsx";
import RootForm from "./components/RootForm/RootForm.jsx";
import Form from "./components/forms/Form";
import SignIn from "./pages/SignIn/SignIn.jsx";
import PrivateRoute from "./components/PrivateRoute";
import { withInputValues } from "./contexts/inputContext";

Amplify.configure(awsExports);

function App() {
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem("MFE_Token") || '');

  const fetchJwtToken = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      sessionStorage.setItem("MFE_Token", token);
      setJwtToken(token);

      const user = await Auth.currentAuthenticatedUser();
      const username = user.username;
      sessionStorage.setItem("userName", username);
      console.log("username", username);
    } catch (error) {
      console.log('Error fetching JWT token:', error);
    }
  };

  useEffect(() => {
    if (!jwtToken) {
      fetchJwtToken();
    }
  }, [jwtToken]);

  const handleSignOut = async () => {
    console.log("handleSignOut");
    sessionStorage.removeItem('MFE_Token');
    sessionStorage.removeItem('userName');
    await Auth.signOut();
    setJwtToken('');
  };

  return (
    <Router>
      <Header title={dynamicTitle} onSignOut={handleSignOut} />
      <Routes>
        <Route path="/signin" element={<SignIn setJwtToken={setJwtToken} />} />
        
        <Route 
          path="/" 
          element={
            <PrivateRoute>
              <Home setDynamicTitle={setDynamicTitle} />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/home" 
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/rootform" 
          element={
            <PrivateRoute>
              <RootForm />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/form" 
          element={
            <PrivateRoute>
              <Form setDynamicTitle={setDynamicTitle} />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/download" 
          element={
            <PrivateRoute>
              <DownloadPage />
            </PrivateRoute>
          } 
        />

        {/* Fallback route */}
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </Router>
  );
}

export default withInputValues(App);
